.Topic {
    padding: 15px;
    background-color: white;
}

.Block_Transparent {
    padding: 15px;
}

.Block_Transparent2 {
    padding: 15px;
    background-color: #F4F8FC;
}

.Graph_Group {
    display: flex;
    flex-direction: row;
}

.Graph_Box {
    width: 100%;
    margin-right: 15px;
    background-color: white;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.Graph_item {
    width: 100%;
    margin-right: 15px;
    flex-grow: 1;
    background-color: white;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
}

.headCard {
    background-color: #c84ba1;
    color: white;
    font-weight: bold;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid var(--btn-primary);
    line-height: 30px;
    padding: 15px 7px 19px;
}

.contentCard {
    padding: 15px;
    background-color: white;
}

.Graph_Box.W_33 {
    width: 50%;
}

.Graph_Box:last-child {
    margin-right: 0;
}

.Graph_Box .Content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: center;
}

    .Graph_Box .Content .Header {
        text-align: left;
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 8.5px;
        /*height: 30px;*/
    }

        .Graph_Box .Content .Header span {
            display: block;
        }

    .Graph_Box .Content .Content {
        padding: 8.5px;
        margin-top: 10px;
    }

.Graph_Box .Footer {
    border-top: 1px solid var(--border-color);
    text-align: center;
    padding-top: 8.5px;
}

.Doughnut_Usage {
    width: 45%;
    margin: 10% auto;
}

.SuscriptionsCannellation {
    width: 45%;
    margin: 0 auto;
}

.Color_Purple_00 {
    color: var(--color-purple-00);
}

.Color_Purple_01 {
    color: var(--color-purple-01);
}

.Color_Purple_02 {
    color: var(--color-purple-02);
}

.Color_Blue_03 {
    color: var(--color-blue-03);
}

.Color_Orange_03 {
    color: var(--color-orange-03);
}

.Color_Orange_04 {
    color: var(--color-orange-04);
}

.Color_Pink_01 {
    color: var(--color-pink-01);
}

.color1 {
    color: var(--btn-primary);
}

.line {
    width: 500px;
    height: 45px;
    text-align: center;
}

.line1 {
    width: 110px;
    height: 45px;
    text-align: center;
}

.pad {
    padding-top: 12.31px;
    padding-bottom: 12.31px;
    padding-right: 12.31px;
    padding-left: 12.31px;
}

.foit {
    display: flex;
    justify-content: center;
    align-items: center;
}

.w_480px {
    width: 480px;
}

.fontColorHead {
    color: var(--link-black);
    font-size: 1.2rem;
}

.fontColorBody {
    color: #c84ba1;
    font-size: 1.2rem;
}