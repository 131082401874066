.Head_Feature {
    height: calc(383px + 88px);
    position: relative;
}

    .Head_Feature .Img_999 {
        width: auto;
        height: 413px;
        position: absolute;
        bottom: 0;
        right: 4em;
        z-index: 0;
    }

    .Head_Feature .Top {
        height: 383px;
        background: linear-gradient(90deg, rgba(6,61,149,1) 0%, rgba(33,91,172,1) 58%, rgba(130,199,252,1) 100%);
    }

        .Head_Feature .Top .Feature {
            padding: 5em 0em 0 5em;
            font-family: 'Prompt';
            color: #fff;
        }

            .Head_Feature .Top .Feature h2 {
                font-size: 32px;
                font-weight: 400;
                margin-bottom: 0.7em;
                line-height: 150%;
            }

            .Head_Feature .Top .Feature p {
                font-size: 1.5rem;
                font-weight: 300;
                margin-bottom: 1.8em;
            }

    .Head_Feature .Bottom {
        height: 88px;
        background-color: #F4F3FB;
    }

.Login_inner_layout {
    margin: 0px auto;
    margin-top: -305px;
    display: block;
    max-width: 1100px;
    padding: 30px;
    border: 1px solid var(--border-color);
    border-radius: 15px 15px 0 0;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-family: 'Prompt';
    font-size: 1rem;
    position: relative;
}

.pad {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 30px;
}

.bordertabel {
    border-radius: 5px;
}

.fontpage {
    font-size: 1.2rem;
    background-color: #f2f4f7;
}

.colorback {
    background: var(--btn-primary);
    color: var(--background-primary);
}

.fontsize {
    font-size: 40px;
    text-align: center;
    z-index: 10;
    /*position: absolute;
    margin-right: 350px;*/
}

.Head_Feature2 {
    position: relative;
}

@media only screen and (max-width: 992px) {
    .fontsize {
        /*font-size: 3vw;*/
        padding: 30px 30px 30px 30px;
    }

    .Feature {
        padding: 1em !important;
    }
}