@font-face {
    font-family: 'Mitr';
    src: local('Mitr'), url(./fonts/Mitr-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Mitr';
    font-weight: 700;
    src: local('Mitr'), url(./fonts/Mitr-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Mitr';
    font-weight: 600;
    src: local('Mitr'), url(./fonts/Mitr-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Mitr';
    font-weight: 500;
    src: local('Mitr'), url(./fonts/Mitr-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Mitr';
    font-weight: 300;
    src: local('Mitr'), url(./fonts/Mitr-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Mitr';
    font-weight: 200;
    src: local('Mitr'), url(./fonts/Mitr-ExtraLight.ttf) format('truetype');
}

/**************************************************/
@font-face {
    font-family: 'Prompt';
    src: local('Prompt'), url(./fonts/Prompt-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Prompt';
    font-style: italic;
    src: local('Prompt'), url(./fonts/Prompt-Italic.ttf) format('truetype');
}

@font-face {
    font-family: 'Prompt';
    font-weight: 100;
    font-style: italic;
    src: local('Prompt'), url(./fonts/Prompt-ThinItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Prompt';
    font-weight: 100;
    src: local('Prompt'), url(./fonts/Prompt-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'Prompt';
    font-weight: 200;
    font-style: italic;
    src: local('Prompt'), url(./fonts/Prompt-ExtraLightItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Prompt';
    font-weight: 200;
    src: local('Prompt'), url(./fonts/Prompt-ExtraLight.ttf) format('truetype');
}

@font-face {
    font-family: 'Prompt';
    font-weight: 300;
    src: local('Prompt'), url(./fonts/Prompt-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Prompt';
    font-weight: 300;
    font-style: italic;
    src: local('Prompt'), url(./fonts/Prompt-LightItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Prompt';
    font-weight: 500;
    src: local('Prompt'), url(./fonts/Prompt-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Prompt';
    font-weight: 500;
    font-style: italic;
    src: local('Prompt'), url(./fonts/Prompt-MediumItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Prompt';
    font-weight: 600;
    src: local('Prompt'), url(./fonts/Prompt-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Prompt';
    font-weight: 600;
    font-style: italic;
    src: local('Prompt'), url(./fonts/Prompt-SemiBoldItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Prompt';
    font-weight: 700;
    src: local('Prompt'), url(./fonts/Prompt-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Prompt';
    font-weight: 700;
    font-style: italic;
    src: local('Prompt'), url(./fonts/Prompt-BoldItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Prompt';
    font-weight: 800;
    src: local('Prompt'), url(./fonts/Prompt-ExtraBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Prompt';
    font-weight: 800;
    font-style: italic;
    src: local('Prompt'), url(./fonts/Prompt-ExtraBoldItalic.ttf) format('truetype');
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-family: 'Prompt';
    color: var(--color-black-01);
}

:root {
    --border-color: #dee2e6;
    --border-radius: 0.25rem;
    --background-primary: #FFFFFF;
    --background-form: #F6F7F9;
    --btn-primary: #063D95;
    --btn-primary-border-color: #13479B;
    --btn-primary-hover: #3565b3;
    --btn-primary-border-color-hover: #13479B;
    --link-black: #333;
    --link-blue: #359CE3;
    --link-blue-01: #0366d6;
    --menu-width: 250px;
    --header-user-info-height: 65px;
    --padding-default: 15px;
    --padding-x2: 32px;
    --color-white-01: #fff;
    --color-black-01: #333;
    --color-gray-02: #C6C9D2;
    --color-gray-03: #6C757D;
    --color-blue-01: #063D95;
    --color-blue-02: #699DE0;
    --color-blue-03: #55D8FE;
    --color-purple-00: #4541e5;
    --color-purple-01: #A19FFB;
    --color-purple-02: #EDECFE;
    --color-orange-03: #FFDA83;
    --color-orange-04: #FF8373;
    --color-pink-01: #F84B89;
    --color-pink-02: #C84BA1;
    --color-pink-03: #fdf;
    --primary-pink: #D84CA3;
    --primary-purple: #81489A;
    --primary-blue: #64AEEE;
    --primary-black: #1E1E1E;
    --primary-gray: #D9D9D9;
    --primary-yellow: #FFDC2E;
}

header {
    position: absolute;
    z-index: 1;
    width: 100%;
    min-height: 90px;
}

textarea {
    resize: none;
}

.text-pink-01,
.text-pink-01:hover {
    color: var(--color-pink-01);
}

.text-pink-02,
.text-pink-02:hover {
    color: var(--color-pink-02);
}

.text-purple-01,
.text-purple-01:hover {
    color: var(--primary-purple);
}

.bg-pink-01 {
    background-color: var(--color-pink-01);
}

.bg-pink-02 {
    background-color: var(--color-pink-02);
}

.bg-pink-03 {
    background-color: var(--color-pink-03);
}

.border-pink-02 {
    border-color: var(--color-pink-02) !important;
}

.text-blue-01 {
    color: var(--color-blue-01);
}

.bg-blue-01 {
    background-color: var(--color-blue-01);
}

.bg-blue-02 {
    background-color: var(--color-blue-02);
}

.bg-gray-02 {
    background-color: var(--color-gray-02);
}

.bg-gray-03 {
    background-color: var(--color-gray-03);
}

.border-blue-02 {
    border-color: var(--color-blue-02) !important;
}

.btn-primary {
    background-color: var(--btn-primary);
    border-color: var(--btn-primary-border-color);
}

.btn-primary:hover {
    background-color: var(--btn-primary-hover);
    border-color: var(--btn-primary-border-color-hover);
}

.w-33 {
    width: 33%;
}

h3 {
    font-size: 48px;
    font-weight: 400;
}

h5 {
    font-size: 24px;
    font-weight: 200;
}

h6 {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: var(--btn-primary);
}

body {
    font-size: 1rem;
}

pre {
    font-family: 'Prompt';
    font-size: 1rem;
    white-space: pre-wrap;
    padding: 0;
    margin: 0;
}

.container {
    height: 100%;
}

.fs-6 {
    font-size: 1.1rem !important;
}

.alert {
    font-family: 'Prompt';
    font-size: 1rem;
}

.form-label {
    margin-bottom: 0.2rem;
}

.form-control,
.input-group-text,
.form-select,
.btn {
    font-size: 1rem;
}

.environment {
    width: 100%;
    color: #fff;
    text-align: center;
    position: absolute;
    z-index: 5;
}

.background-blur {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.8;
    backdrop-filter: blur(10px);
}

.hint {
    display: inline-block;
    width: 16px;
    height: 16px;
    text-align: center;
    padding: 1px;
    background-color: var(--btn-primary);
    border-radius: 50%;
    color: white;
    font-size: 0.8rem;
    vertical-align: top;
    margin: 1px;
    margin-left: 2px;
    margin-right: 2px;
    line-height: 1;
}

a,
.link {
    color: #0366d6;
    cursor: pointer;
}

.fs-7 {
    font-size: 0.9rem !important;
}

.link_contact {
    color: #359CE3;
    text-decoration: none;
    cursor: pointer;
}

code {
    color: #E01A76;
}

.font-color-privary {
    color: var(--btn-primary);
}

.form-check-input {
    font-size: 1.4rem;
    margin-left: -1.05em !important;
    margin-top: 0em;
}

.form-check-label {
    margin-left: 0.35em;
}

.form-switch .form-check-input {
    width: 2.5em;
    height: 1.5em;
}

.datepicker-picker button {
    font-family: 'Prompt';
    border-width: 1px;
    border-color: transparent;
    padding: 0.3rem;
    background-color: #fff;
}

.datepicker-picker .datepicker-controls {
    display: flex;
}

.datepicker-picker .datepicker-controls .view-switch {
    font-size: 1.1rem;
}

.datepicker-picker .datepicker-controls .prev-btn:hover,
.datepicker-picker .datepicker-controls .view-switch:hover,
.datepicker-picker .datepicker-controls .next-btn:hover {
    background-color: #f3f3f3;
}

.datepicker-picker .datepicker-controls .today-btn,
.datepicker-picker .datepicker-controls .clear-btn {
    flex-grow: 1;
    border: 1px solid #dbdbdb;
    margin: 1px;
}

.datepicker-picker .datepicker-controls .today-btn:hover,
.datepicker-picker .datepicker-controls .clear-btn:hover {
    border-color: #999;
}

.datepicker-picker .datepicker-cell.day.next {
    color: #ccc;
}

a.profile-dropdown {
    font-family: 'Prompt';
    font-size: 1rem;
    text-decoration: none;
    color: var(--link-black);
}

a.profile-dropdown img {
    height: 30px;
    width: auto;
    border-radius: 100%;
    border: 1px solid transparent;
}

.dropdown-toggle::after {
    font-size: 1.8em;
    vertical-align: middle;
}

.dropdown-menu {
    font-family: 'Prompt';
    font-size: 1rem;
}

.w-100px {
    max-width: 100px;
    width: 100px;
}

.w-200px {
    max-width: 200px;
    width: 200px;
}

.w-500px {
    max-width: 500px;
    width: 500px;
}

.w-800px {
    max-width: 800px;
    width: 100%;
}

.w-1000px {
    max-width: 1400px;
    min-width: 1200px;
    width: 100%;
}

.w-1100px {
    max-width: 1400px;
    min-width: 1200px;
    width: 100%;
}

.label-title {
    color: var(--btn-primary)
}

table {
    font-size: 1rem;
}

table th,
table td {
    vertical-align: top;
}

.table.table-summary th {
    font-weight: normal;
    border-bottom: 1px solid var(--border-color);
}

.table.table-summary tr:first-child td {
    padding-top: 0.8rem;
}

.table.table-summary td {
    padding: 0.2em 0.5rem;
}

.custom.accordion {}

.custom.accordion .accordion-item {
    border: none;
    margin-bottom: 1rem;
}

.custom.accordion .accordion-button {
    color: var(--btn-primary);
    font-size: 20px;
    margin: 0;
    padding: 0;
    line-height: 30px;
    font-weight: 400;
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 0.5rem;
    align-items: flex-start;
}

.custom.accordion .accordion-button::after {
    margin-top: 0.3em;
}

.custom.accordion .accordion-button.no-icon::after {
    content: none;
}

.custom.accordion .accordion-body {
    padding: 0;
}

.form-select.no-icon.is-valid:not([multiple]):not([size]),
.form-select.no-icon.is-valid:not([multiple])[size="1"],
.was-validated .form-select.no-icon:valid:not([multiple]):not([size]),
.was-validated .form-select.no-icon:valid:not([multiple])[size="1"] {
    padding-right: 0;
    background-image: none;
}

.form-control.no-icon.is-invalid,
.was-validated .form-control.no-icon:invalid {
    padding-right: 0;
    background-image: none;
}

.form-control.CustomPwd.is-invalid,
.was-validated .form-control.CustomPwd:invalid {
    background-position: right calc(2.375em + .1875rem) center;
}

.form-control.CustomPwd.is-valid,
.was-validated .form-control.CustomPwd:valid {
    background-position: right calc(2.375em + .1875rem) center;
}

.standard-box {
    /* display: flex; */
    /* flex-direction: column; */
    /* width: 344px; */
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: white;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    font-size: 1rem;
    overflow: hidden;
    font-family: 'Prompt';
    padding: var(--padding-x2);
    text-align: left;
}

.customer-id {
    background-color: var(--color-pink-02);
    color: white;
    margin-top: -0.2em;
    padding: 0.5em 1.2em;
    line-height: 1.2;
    display: inline-block;
    border-radius: var(--border-radius);
}

.customer-id label {
    display: block;
}

.customer-id label:first-child {
    font-size: 0.7rem;
}

.btn-secondary-trn:active {
    background-color: var(--color-gray-02) !important;
    color: var(--background-primary) !important;
}

.btn-secondary-trn {
    background-color: transparent;
    border: 1px solid var(--color-gray-02);
    color: var(--link-black);
}

.btn-secondary-trn:hover {
    background-color: var(--color-gray-03);
    color: var(--background-primary);
}

.btn-primary-trn:active {
    background-color: var(--color-pink-01) !important;
    color: white !important;
    border: 1px solid transparent;
}

.btn-primary-trn {
    background-color: var(--color-pink-02);
    border: 1px solid transparent;
    color: white;
}

.btn-primary-trn:hover {
    background-color: var(--color-pink-01);
    color: white;
    border: 1px solid transparent;
}

.popup-free-alert {
    opacity: 0.5;
    z-index: 5;
    padding: 1em;
    border-radius: 5px;
    margin: 0.5em;
    cursor: default;
}

.popup-free-alert:hover {
    opacity: 1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn;
    animation-duration: 0.8s;
}

.dropdown-toggle.nav-link {
    color: var(--primary-black);

}

.dropdown-divider {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.dropdown-menu.show {
    opacity: 0.9;
    border-radius: 0px;
    padding: 7px;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    background-color: #9f9f9f;
    border-color: var(--bs-btn-disabled-border-color);
}

.carousel-indicators {
    display: none !important;
}

.carousel-control-prev {
    display: none !important;
}

.carousel-control-next {
    display: none !important;
}

.blue-line {
    border: 1px solid var(--btn-primary);
}

.cookie-policy {
    width: 500px;
}

.navbar-collapse * {
    font-size: 1.3rem;
}

@media only screen and (max-width: 500px) {
    .cookie-policy {
        width: auto;
    }

    .dropdown-menu[data-bs-popper] {
        right: 0 !important;
        left: auto;
    }
}

@media only screen and (max-width: 992px) {
    .dropdown-toggle.nav-link {
        font-size: 16px;
    }
}

@media only screen and (max-width: 767px) {
    .dropdown-toggle.nav-link {
        font-size: 20px;
    }
}

@media only screen and (max-width: 1198px) {
    .navbar-collapse * {
        font-size: 1.1rem;
    }
}

#tsparticles {
    position: relative;
    z-index: 10;
}

#div-DBD-Certificate {
    position: relative;
    z-index: 1;
    padding: 0.1em 1em;
}

#div-DBD-Certificate a {
    margin: 0;
    padding: 0;
}

#div-DBD-Certificate::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 1;
    border-radius: 5px;
}

/*****  add media by bootstap contrainer **************/
.video-container {
    overflow: hidden;
}

.video-container iframe {
    margin: auto;
    display: block;
    width: auto;
    height: 720px;
    aspect-ratio: 16 / 9;
}

@media (min-width: 320px) {
    .video-container iframe {
        height: 250px;
    }
}

@media (min-width: 576px) {
    .video-container iframe {
        height: 290px;
    }
}

@media (min-width: 768px) {
    .video-container iframe {
        height: 250px;
    }
}

@media (min-width: 992px) {
    .video-container iframe {
        height: 350px;
    }
}

@media (min-width: 1200px) {
    .video-container iframe {
        height: 415px;

    }
}

@media (min-width: 1400px) {
    .video-container iframe {
        height: 480px;
    }
}

#article_id p {
    margin-bottom: 0rem;
}

/* ////Font Size///// */
#article_id h1 {
    font-size: 24px;
}

#article_id h2 {
    font-size: 20px;
}

#article_id h3 {
    font-size: 18px !important;
}

#article_id .ql-size-small {
    font-size: 12px;
}

#article_id .ql-size-normal {
    font-size: 16px;
}

#article_id .ql-size-large {
    font-size: 20px;
}

#article_id .ql-size-huge {
    font-size: 24px;
}

.ql-align-left {
    text-align: left;
}

.ql-align-center {
    text-align: center;
}

.ql-align-right {
    text-align: right;
}

.ql-indent-1 {
    padding-left: 3em;
}

.ql-indent-2 {
    padding-left: 6em;
}

.ql-indent-3 {
    padding-left: 9em;
}

.ql-indent-4 {
    padding-left: 12em;
}

#article_id ol,
#article_id ul {
    padding-left: 1.5em;
}

#article_id ol li:not(.ql-direction-rtl),
#article_id ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
}

#article_id ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
}

#article_id ol>li,
#article_id ul>li {
    list-style-type: none;
}

#article_id li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
}

#article_id ul>li::before {
    content: '\2022';
}

#article_id ol li:before {
    content: counter(list-0, decimal) '. ';
}

#article_id li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
}

#article_id blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
}

.card:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card {
    padding: var(--bs-card-img-overlay-padding, 1rem);
    border-radius: var(--bs-card-border-radius, 0.25rem);
    background-color: var(--background-form);
    border: none;
}

.card img {
    border-radius: 0.50rem;
}

.card-footer {
    border: none;
    background: none;
}

.card-footer .read-more {
    color: var(--color-blue-02);
}

.card-footer .read-more:hover {
    color: var(--color-pink-01);
}

.card-title:hover {
    color: var(--color-pink-01);
}

/**/
.video-background {
    background: #fff;
    position: relative;
    height: calc(100vh - 120px);
    z-index: 0;
}

.video-foreground {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.video-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.video-background .mute-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 10;
}

.video-background .mute-button button {
    padding: 10px 20px;
    background-color: #ff5722;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.video-background .mute-button button:hover {
    background-color: #e64a19;
}

@media all and (max-width: 600px) {
    .vid-info {
        width: 50%;
        padding: 0.5rem;
    }

    .vid-info h1 {
        margin-bottom: 0.2rem;
    }
}

@media all and (max-width: 500px) {
    .vid-info .acronym {
        display: none;
    }
}