.Bg_Gay1 {
    background-color: #fff;
}

.Border_Top_Gay1 {
    border-top: 1px solid #707070;
}

.Border_Bottom_Gay1 {
    border-bottom: 1px solid #ccc;
}

.Head_Feature {
    height: calc(383px + 88px);
    position: relative;
}

    .Head_Feature .Img_999 {
        width: auto;
        height: 413px;
        position: absolute;
        bottom: 0;
        right: 12em;
    }

    .Head_Feature .Top {
        height: 383px;
        background: linear-gradient(90deg, var(--color-pink-02) 0%, #804B9C 30%, #689FE1 100%);
    }

        .Head_Feature .Top .Feature {
            padding: 3.1em 19em;
            font-family: 'Prompt';
            color: #fff;
        }

            .Head_Feature .Top .Feature h2 {
                font-size: 32px;
                font-weight: 400;
                margin-bottom: 0.7em;
                line-height: 150%;
            }

            .Head_Feature .Top .Feature p {
                font-size: 19px;
                font-weight: 300;
                margin-bottom: 1.8em;
            }

    .Head_Feature .Bottom {
        height: 88px;
        background-color: #F4F3FB;
    }

.btn_free.big {
    padding: 0.5em 3em;
    font-size: 28px;
    font-weight: 500;
    background-color: #232A40;
    border: none;
    color: #fff;
}

.btn_round {
    border-radius: 100em;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.Feature_v1,
.Feature_v1.border_none {
    padding: 0.9em;
    padding-bottom: 3em;
    border-bottom: 1px solid #ccc;
    font-family: 'Prompt';
}

    .Feature_v1.border_none {
        border-bottom: none;
        padding-bottom: 1em;
    }

    .Feature_v1 h3 {
        font-size: 23px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 0.2em;
        color: #262A3F;
        opacity: 0.8;
    }

    .Feature_v1 p {
        text-align: center;
        color: #262A3F;
        opacity: 0.8;
        padding-bottom: 2em;
    }

    .Feature_v1.border_none p {
        padding-bottom: 1em;
        margin-bottom: 0;
    }

    .Feature_v1 .Feature_List {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

        .Feature_v1 .Feature_List div {
            display: flex;
            width: 33.33%;
            margin-bottom: 1.1em;
        }

            .Feature_v1 .Feature_List div img {
                height: 80px;
            }

            .Feature_v1 .Feature_List div label {
                margin-left: 0.8em;
                font-size: 19px;
                font-weight: 300;
                color: #262A3F;
                opacity: 0.8;
                white-space: nowrap;
                line-height: 200%;
            }

        .Feature_v1 .Feature_List .Feature_Box {
            display: block;
            border: 1px solid var(--color-gray-02);
            text-align: center;
            padding: 1.5em;
            border-radius: 5px;
            width: 250px;
            margin: 0 0.2em;
        }

            .Feature_v1 .Feature_List .Feature_Box div,
            .Feature_v1 .Feature_List .Feature_Box label {
                display: block;
                text-align: center;
                width: 100%;
                margin: 0;
            }

.Feature_v2 {
    border-bottom: 1px solid #ccc;
}

    .Feature_v2 .Feature_List {
        padding-top: 2.3em;
        padding-bottom: 5em;
        font-family: 'Prompt';
        width: 1100px;
        margin: 0 auto;
    }

        .Feature_v2 .Feature_List h4 {
            font-weight: 400;
            color: #515565;
            line-height: 150%;
            margin-top: -2px;
            margin-bottom: 1em;
        }

        .Feature_v2 .Feature_List ul {
            list-style: none;
        }

            .Feature_v2 .Feature_List ul li {
                font-size: 15pt;
                font-weight: 300;
                color: #515565;
                opacity: 0.8;
                margin-bottom: 0.7em;
            }

    .Feature_v2 ul li i {
        font-size: 13pt;
        margin-right: 0.8em;
        color: #063D95;
    }

.Feature_v3 {
    padding-top: 2.5em;
    padding-bottom: 3em;
    font-family: 'Prompt';
}

    .Feature_v3 p {
        text-align: center;
        color: #262A3F;
        font-size: 19px;
        font-weight: 300;
        opacity: 0.8;
        padding-bottom: 1em;
    }

.Btn_Free_Trial_v1 {
    background-color: var(--btn-primary);
    color: #fff;
    border-radius: 10em;
    padding: 10px 55px;
}

.Package {
    width: 1100px;
    margin: 0 auto;
    padding-bottom: 20px;
    text-align: center;
}

.Package_box {
    width: calc(260px - 34px);
    padding-top: 46px;
    padding-bottom: 20px;
    padding-left: 16px;
    padding-right: 16px;
    border: 2px solid #E4EAF1;
    background-color: var(--background-primary);
    display: inline-block;
    margin-right: 20px;
    border-radius: 10px;
    margin-bottom: 25px;
    ;
    font-family: 'Prompt';
    position: relative;
    text-align: center;
}

    .Package_box .Recommand {
        position: absolute;
        background-color: #fff;
        border: 2px solid var(--btn-primary);
        top: -2px;
        left: 25%;
        color: var(--btn-primary);
        font-size: 10px;
        padding: 3px 9px;
        border-end-start-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .Package_box .Recommand {
        position: absolute;
        background-color: var(--btn-primary);
        border: 2px solid var(--btn-primary);
        top: -2px;
        left: 25%;
        color: #fff;
        font-size: 10px;
        padding: 3px 9px;
        border-end-start-radius: 5px;
        border-bottom-right-radius: 5px;
    }

        .Package_box .Recommand i {
            font-size: 9px;
            margin-right: 2px;
        }

    .Package_box:last-child {
        margin-right: 0;
    }

    .Package_box:hover,
    .Package_box.Selected {
        border-color: var(--btn-primary);
    }

    .Package_box h4 {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 25px;
    }

    .Package_box h2 {
        margin-bottom: 10px;
    }

    .Package_box .Unit {
        color: #C6C9D2;
        font-size: 15px;
        padding-bottom: 16px;
        position: relative;
    }

        .Package_box .Unit::after {
            content: '';
            position: absolute;
            width: 50%;
            height: 2px;
            background-color: #767676;
            opacity: 0.1;
            z-index: 1;
            left: 25%;
            bottom: 0;
        }

    .Package_box .Spac {
        font-size: 12px;
        padding-top: 6px;
        padding-bottom: 8px;
        position: relative;
    }

        .Package_box .Spac::after {
            content: '';
            position: absolute;
            width: 50%;
            height: 2px;
            background-color: #767676;
            opacity: 0.1;
            z-index: 1;
            left: 25%;
            bottom: 0;
        }

    .Package_box .Feature {
        text-align: left;
        font-size: 12px;
        margin-top: 19px;
        margin-bottom: 30px;
        color: #424E5D;
        white-space: normal;
    }

        .Package_box .Feature ul {
            padding: 0;
            padding-left: 5px;
            margin: 0;
            list-style: none;
        }

        .Package_box .Feature li {
            padding-bottom: 9px;
        }

            .Package_box .Feature li i {
                margin-right: 5px;
            }

    .Package_box button {
        background-color: #fff;
        border: 2px solid var(--btn-primary);
        color: var(--btn-primary);
        border-radius: 10em;
        font-size: 1rem;
        padding: 5px 40px;
    }

    .Package_box:hover button {
        background-color: var(--btn-primary);
        border: 2px solid var(--btn-primary);
        color: #fff;
        border-radius: 10em;
        font-size: 1rem;
        padding: 5px 40px;
    }

.Package_Period {
    background-color: white;
    color: var(--btn-primary);
    width: auto;
    display: inline-block;
    padding: 2px;
    border-radius: 3em;
    border: 1px solid var(--border-color);
    text-align: center;
    flex-grow: 0 !important;
    margin: 0 auto;
}

    .Package_Period .Form_Check {
        display: inline-block;
        padding: 0px;
        margin: 0px;
    }

        .Package_Period .Form_Check input[type=radio] {
            width: 0px;
            height: 0px;
            opacity: 0;
        }

        .Package_Period .Form_Check label {
            padding: 3px 15px;
            cursor: pointer;
            font-size: 1rem;
        }

        .Package_Period .Form_Check input[type=radio]:checked + label {
            color: white;
            background-color: var(--btn-primary);
            border-radius: 3em;
        }

.Package_Slide {
    margin: 0 auto;
    width: 1000px;
    white-space: nowrap;
    overflow: auto;
}

    .Package_Slide::after {
        content: '';
        width: 2px;
        height: 400px;
        display: block;
        position: absolute;
        background-color: #ccc;
        top: 55px;
        z-index: 0;
    }

.Feature_v4 {
    text-align: center;
    font-family: 'Prompt';
    padding: 22px 0px;
}

    .Feature_v4 p {
        color: #97999E;
        font-size: 15px;
        margin-top: 1px;
        margin-bottom: 24px
    }

.Btn_Free_Trial_v2 {
    background-color: var(--btn-primary);
    border: 1px solid var(--btn-primary);
    color: #fff;
    border-radius: 10em;
    padding: 5px 55px;
    font-size: 19px;
}

    .Btn_Free_Trial_v2 i {
        font-size: 13px;
        margin-right: 5px;
    }

.Feature_v5 {
    padding: 38px 30px;
    text-align: center;
    font-family: 'Prompt';
    color: #707070;
}

    .Feature_v5 .Feathure_list {
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        width: 1000px;
        margin: 0 auto;
        padding-left: 5%;
    }

    .Feature_v5 .Feathure_Align {
        flex: 50%;
        margin-bottom: 50px;
    }

    .Feature_v5 h3 {
        font-size: 24px;
        margin-bottom: 3em;
        color: #515565;
    }

    .Feature_v5 .Feathure_Align:last-child {
        margin-right: 0;
    }

    .Feature_v5 .Feathure_box {
        display: flex;
    }

        .Feature_v5 .Feathure_box div {
            text-align: left;
        }

            .Feature_v5 .Feathure_box div:first-child {
                width: 60px;
            }

            .Feature_v5 .Feathure_box div img {
                width: 32px;
                height: auto;
            }

            .Feature_v5 .Feathure_box div p {
                font-size: 12px;
            }

.colorh3 {
    text-align: center;
    font-family: 'Prompt';
    font-size: 26px;
    color: #515565;
    margin-top: 40px;
    margin-bottom: 15px;
}

.fontsize {
    position: relative;
}

@media (max-width: 1300px) {
    .Head_Feature .Img_999 {
        height: 30vw !important;
        right: 2em;
    }

    .Feature_sizetext {
        padding: 3em 4em 0em 13em !important;
        /*width: 900px !important;*/
    }

    .Package {
        width: auto !important;
    }

    .Feature_List {
        width: 100% !important;
    }

    .Head_Feature {
        height: auto !important;
    }
}

@media only screen and (max-width: 994px) {
    .Package {
        width: auto !important;
    }

    .Package_Mobile {
        width: 750px !important;
    }

    .Head_Feature .Img_999 {
        height: 30vw !important;
        right: 4em;
    }

    .Feature_List {
        padding: 0 !important;
        width: 100% !important;
    }

        .Feature_List h4 {
            display: block !important;
            padding-top: 10px !important;
            text-align: center !important;
        }

    .Feathure_list {
        display: block !important;
        width: 100% !important;
        padding: 0 !important;
        text-align: center !important;
    }

    .Feature_sizetext {
        padding: 3em 4em 0em 4em !important;
    }

    .Feature_v2_pic {
        display: block !important;
    }

    .Feature_v2_text {
        font-size: 16px !important;
    }

    .img_Feature_v2 {
        height: 15em;
        text-align: center;
        margin-top: 20px;
    }

    .Feature_List_Mobile {
        width: fit-content !important;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .Feathure_box {
        display: inline-flex !important;
    }

    .Feature_List_Mobile2 {
        width: 100% !important;
    }

    .Feature_List_Mobile3 {
        width: 100% !important;
        text-align: center;
    }

    .fontsize {
        font-size: 2vw !important;
    }

    .Feature_List_pic {
        width: 50% !important;
        display: block !important;
        margin-left: 38vw;
    }

    .Top {
        height: auto !important;
    }

    .Feature_v3_mobile {
        margin: 0 1em 0 1em;
    }
}

@media only screen and (max-width: 768px) {
    .fontsize h2 {
        font-size: 26px !important;
    }

    .fontsize p {
        font-size: 19px !important;
    }

    .fontsize button {
        font-size: 22px !important;
        margin-bottom: 15px;
    }

    .Feature_List_pic {
        width: 50% !important;
        margin-left: 32vw;
    }

    .Package_Mobile {
        width: 500px !important;
    }
}

@media only screen and (max-width: 554px) {
    .Img_999 {
        display: none;
    }

    .Package_Mobile {
        width: 370px !important;
    }

    .Feature_List_pic {
        width: 50% !important;
        margin-left: 20vw;
    }

    .fontsize h2 {
        font-size: 24px !important;
    }

    .fontsize p {
        font-size: 18px !important;
    }

    .fontsize button {
        font-size: 20px !important;
    }

    .Feature_sizetext {
        justify-content: center;
        align-items: center;
        display: flex;
        text-align: center !important;
    }

    .Feature_sizetext {
        padding: 3em 1em 0em 1em !important;
    }

    .img_Feature_v2 {
        height: 12em;
        text-align: center;
        margin-top: 20px;
    }
}