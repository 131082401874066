.Register_Step_Layout {
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 755px;
    min-height: 520px;
    height: auto;
    display: flex;
    flex-direction: row;
}

.Register_Step_Layout .Controller {
    flex-grow: 1;
    width: 100%;
    max-width: 508px;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.Register_Step_Layout .Image {
    width: 240px;
    height: 520px;
    flex: none;
}

.Register_Step_Layout .Image img {
    width: 100%;
    height: 100%;
    ;
}



.Nav_step_V2 {
    margin: 0 0;
    font-family: 'Prompt';
}

.Nav_step_V2 .Progress_Bar {
    display: flex;
    width: 320px;
    counter-reset: step;
    padding: 0;
}

.Nav_step_V2 .Progress_Bar li {
    list-style-type: none;
    position: relative;
    text-align: left;
    display: inline-block;
    width: 100%;
    color: var(--btn-primary);
}

.Nav_step_V2 .Progress_Bar li:before {
    content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border: 1px solid var(--border-color);
    display: block;
    text-align: center;
    margin: 0 0 3px 0;
    border-radius: 50%;
    background-color: var(--border-color);
    color: var(--btn-primary);
    position: relative;
    z-index: 1;
}

.Nav_step_V2 .Progress_Bar li.active::before {
    border-color: var(--color-pink-02);
    background-color: var(--color-pink-02);
    color: #fff;
}

.Nav_step_V2 .Progress_Bar li::after {
    content: '';
    width: 100%;
    height: 8px;
    background-color: var(--border-color);
    top: 20%;
    left: 0;
    position: absolute;
    z-index: 0;
}

.Nav_step_V2 .Progress_Bar li:last-child::after {
    display: none;
}

.Register_Step_Layout .Controller .Content {
    margin-right: 48px;
    margin-top: 28px;
    display: flex;
    flex-grow: 1;
}

@media only screen and (max-width: 767px) {
    .Register_Step_Layout {
        width: 100%;
        padding: 0px 2rem 1rem 2rem;
    }

    .Register_Step_Layout .Image {
        display: none;
        ;
    }

    .Register_Step_Layout .Controller {
        max-width: 100%;
    }

    .Register_Step_Layout .Controller .Content {
        margin-right: 0px;
    }
}

/*** v.1 *************************************************************/
.Register_customer_layout {
    display: flex;
    flex-direction: column;
    width: 800px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: white;
    margin: 0 auto;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    font-size: 1rem;
    overflow: hidden;
    font-family: 'Prompt';
}

.Register_customer_layout.No_border_auto_height {
    border: none;
    box-shadow: none;
    height: auto;
    min-height: auto;
}

.Register_customer_layout2 {
    display: flex;
    flex-direction: column;
    width: auto;
    min-width: 570px;
    height: auto;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: white;
    margin: 0 auto;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    font-size: 1rem;
    font-family: 'Prompt';
    padding: 32px;
}

.Register_customer_layout3 {
    display: flex;
    flex-direction: column;
    width: 344px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: white;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    font-size: 1rem;
    overflow: hidden;
    font-family: 'Prompt';
    padding: 32px;
    text-align: left;
}

.Register_customer_layout4 {
    display: flex;
    flex-direction: column;
    width: 460px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: white;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    overflow: hidden;
    font-family: 'Prompt';
    padding: 32px;
    text-align: left;
}

.switchField {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #DEE2E6;
    border: 1px solid #C6C9D2;
    border-radius: 5px;
    height: 33px;
    align-items: center;
    padding-left: 3px;
    padding-right: 3px;
}

.switchField input {
    font-size: 0px;
    width: 0px;
    height: 0px;
}

.switchField label {
    width: 50%;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
}

.switchField input:checked+label {
    background: #FFFFFF;
    border-radius: 5px;
    height: 27px;
    padding-top: 3px;
    align-items: center;
}

.Nav_step {
    margin: 0 auto;
    width: 800px;
    min-width: 800px;
    font-family: 'Prompt';
}

.progressbar {
    counter-reset: step;
    margin: 0;
    padding: 0;
    display: flex;
}

.progressbar li {
    list-style-type: none;
    position: relative;
    text-align: center;
    display: inline-block;
    width: 100%;
    color: var(--btn-primary);
}

.progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border: 1px solid var(--border-color);
    display: block;
    text-align: center;
    margin: 0 auto 3px auto;
    border-radius: 50%;
    background-color: var(--border-color);
    color: var(--btn-primary);
    position: relative;
    z-index: 1;
}

.progressbar li.active::before {
    border-color: var(--btn-primary);
    background-color: var(--btn-primary);
    color: #fff;
}

.progressbar li::after {
    content: '';
    width: 100%;
    height: 8px;
    background-color: var(--border-color);
    top: 20%;
    left: 50%;
    position: absolute;
    z-index: 0;
}

.progressbar li:last-child::after {
    display: none;
}

.Register_customer_layout .Content {
    min-height: 100%;
    flex-grow: 1;
}

.Imagemst {
    text-align: left;
    padding-top: 341px;
    padding-left: 32px;
}

.Register_customer_layout .Content .Image {
    text-align: center;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-right: 32px;
    padding-left: 32px;
}

.Register_customer_layout .Content_block {
    min-height: 100%;
    display: flex;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-right: 32px;
    padding-left: 32px;
}

.Register_inner_layout {
    display: flex;
    flex-grow: 1;
}

.Register_inner_layout1 {
    padding: 32px;
    display: flex;
    flex-grow: 1;
}

.Register_Form {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.Register_From_Bg {
    background-color: var(--background-form);
    padding: 8.5px;
}

.Register_From_Bg .Header {
    color: var(--btn-primary);
    padding: 8.5px 0px;
    border-bottom: 1px solid var(--border-color);
    display: block;
    display: flex;
}

.Register_From_Bg .Bank_Box {
    padding: 4px 0px;
    border-bottom: 1px solid var(--border-color);
    display: block;
    margin: 0 auto;
    width: 300px;
}

.Register_From_Bg .Bank_Box div {
    display: inline-block;
    vertical-align: top;
}

.Register_From_Bg .Bank_Box img {
    padding-right: 8.5px;
}

.Register_Form div:first-child {
    flex-grow: 1;
}

.Register_Form h6 {
    text-align: center;
    border-bottom: 0px solid var(--border-color);
}

.Register_Form button[type=submit] {
    background-color: var(--btn-primary);
    border-color: var(--btn-primary-border-color);
}

.Register_Form button[type=submit]:hover {
    background-color: var(--btn-primary-hover);
}

/**********************************************************************/
.Package_Period {
    background-color: white;
    color: var(--btn-primary);
    border: 1px solid var(--border-color);
    width: auto;
    display: inline-block;
    padding: 2px;
    border-radius: 3em;
    text-align: center;
    flex-grow: 0 !important;
    margin: 0 auto;
}

.Package_Period .Form_Check {
    display: inline-block;
    padding: 0px;
    margin: 0px;
}

.Package_Period .Form_Check input[type=radio] {
    width: 0px;
    height: 0px;
    opacity: 0;
}

.Package_Period .Form_Check label {
    padding: 3px 15px;
    cursor: pointer;
}

.Package_Period .Form_Check input[type=radio]:checked+label {
    color: white;
    background-color: var(--btn-primary);
    border-radius: 3em;
}

.Package_Slide {
    /*width: 393px;*/
    white-space: nowrap;
    overflow: auto;
}

.Package_box {
    width: calc(220px - 34px);
    padding-top: 46px;
    padding-bottom: 20px;
    padding-left: 8px;
    padding-right: 8px;
    border: 1px solid var(--border-color);
    background-color: #FFFFFF;
    display: inline-block;
    margin-right: 8px;
    border-radius: 10px;
    margin-bottom: 16px;
    ;
    font-family: 'Prompt';
    position: relative;
    text-align: center;
}

.Package_box .Recommand {
    position: absolute;
    background-color: var(--btn-primary);
    border: 1px solid var(--btn-primary);
    top: -2px;
    left: 10%;
    right: 10%;
    color: #fff;
    font-size: 1rem;
    padding: 3px 9px;
    border-end-start-radius: 5px;
    border-bottom-right-radius: 5px;
}

.Package_box .Recommand i {
    font-size: 1rem;
    margin-right: 2px;
}

.Package_box:last-child {
    margin-right: 0;
}

.Package_box:hover,
.Package_box.Selected {
    border-color: var(--color-pink-02);
    border-width: 3px;
    width: calc(220px - 34px);
    padding-left: 6.5px;
    padding-right: 6.5px;
    padding-top: 44.5px;
    padding-bottom: 18.5px;
    /*--btn-primary*/
}

.Package_box:hover .Recommand,
.Package_box.Selected .Recommand {
    top: -3.5px;
}

.Package_box h4,
.Package_box h2 {
    color: #343442;
}

.Package_box h4 {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 15px;
}

.Package_box h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.Package_box .Unit {
    color: #C6C9D2;
    font-size: 0.9rem;
    padding-bottom: 16px;
}

.Package_box .Unit::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 1px;
    background-color: #767676;
    opacity: 0.1;
    z-index: 1;
    left: 25%;
    bottom: 0;
}

.Package_box .Spac {
    font-size: 0.9rem;
    padding-top: 6px;
    padding-bottom: 8px;
    position: relative;
}

.Package_box .Spac::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 1px;
    background-color: #767676;
    opacity: 0.1;
    z-index: 1;
    left: 25%;
    bottom: 0;
}

.Package_box .Feature {
    text-align: left;
    font-size: 0.8rem;
    margin-top: 19px;
    margin-bottom: 30px;
    color: #424E5D;
}

.Package_box .Feature ul {
    padding: 0;
    padding-left: 5px;
    margin: 0;
    list-style: none;
}

.Package_box .Feature li {
    padding-bottom: 9px;
}

.Package_box .Feature li i {
    margin-right: 5px;
}

.Package_box button {
    margin-top: 15px;
    background-color: #fff;
    border: 1px solid var(--btn-primary);
    color: var(--btn-primary);
    padding: 5px;
    border-radius: 10em;
    font-size: 1rem;
    width: 100%;
}

.Package_box:hover button,
.Package_box.Selected button {
    background-color: var(--btn-primary);
    border: 1px solid var(--btn-primary);
    color: #fff;
    border-radius: 10em;
    width: 100%;
}

.Payment_Method {
    display: flex;
    flex-direction: row;
}

.Payment_Method .Item * {
    cursor: pointer;
}

.Payment_Method .Item {
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: white;
    padding: 15px 8.5px;
    margin-right: 8.5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 33.33%;
}

.Payment_Method .Item:last-child {
    margin-right: 0px;
}

.Payment_Method .Item img {
    display: inline-block;
}

.Payment_Method .Item label {
    font-size: 1rem;
    padding: 8.5px 0px;
}

.Payment_Method .Item button,
.Payment_Method .Item input[type=button] {
    font-size: 1rem;
    width: 100%;
    padding: 5px 15px;
    border-radius: 30px;
    border: 1px solid var(--btn-primary);
    background-color: white;
    color: var(--btn-primary);
}

.Payment_Method .Item.Selected,
.Payment_Method .Item:hover {
    border-color: var(--btn-primary) !important;
}

.Payment_Method .Item.Selected button,
.Payment_Method .Item.Selected input[type=button],
.Payment_Method .Item:hover button,
.Payment_Method .Item:hover input[type=button] {
    background-color: var(--btn-primary);
    color: white;
}

/****************************************************************/
.TermsAndCondition {
    font-family: 'Prompt';
    color: var(--link-black);
    font-size: 1.2rem;
}

.TermsAndCondition h2 {
    color: var(--btn-primary-hover);
    margin-bottom: 1em;
}

.TermsAndCondition h5 {
    color: var(--btn-primary-hover);
    margin-top: 2em;
    margin-bottom: 1em;
    text-indent: 50px;
}

.TermsAndCondition p {
    text-indent: 50px;
}

.textp {
    text-indent: 80px !important;
}

.textp1 {
    text-indent: 110px !important;
}

.textp2 {
    text-indent: 90px !important;
}

.smt {
    font-size: 1rem;
    color: var(--link-black);
    font-family: Prompt;
}

.pad {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-right: 32px;
    padding-left: 32px;
}

.colorh6 {
    color: var(--btn-primary);
    font-size: 20px;
}

.colorh5 {
    color: var(--btn-primary);
    font-size: 24px;
}

.thxpage {
    width: 50%;
    text-align: center;
    margin: auto;
}

.body2 {
    font-size: 1rem;
}

.printBtn {
    box-sizing: border-box;
    border: 1px solid #6C757D;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    color: var(--link-black);
    background-color: #FFFFFF;
    font-size: 1rem;
    height: 91px;
}

.dropdownColor {
    background-color: #FFFFFF;
    border: 1px solid #CED4DA;
    border-radius: 5px;
    font-size: 1rem;
    color: var(--link-black);
    height: 33px;
}

.btnColor {
    background-color: var(--btn-primary);
    border: none;
}

.btnColor1 {
    background-color: var(--btn-primary);
}

.pricetag {
    margin-top: 145px;
}

.msg {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    color: #C6C9D2;
}

.msg2 {
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
    color: #C6C9D2;
}

.extra {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    width: auto;
    min-height: 58px;
    height: auto;
    border: 1px solid var(--border-color);
    border-radius: 5px;
}

.pay {
    min-height: 202px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    text-align: center;
    padding: 35px 20px 35px 20px;
}

.pay2 {
    display: inline-flex;
}

.pay3 {
    display: block;
    height: 33px;
    border: 1px solid #CED4DA;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
}

.transform {
    background: #CFE2FF;
    border: 1px solid var(--btn-primary);
    border-radius: 5px;
    width: 158px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.Subtitle1 {
    font-size: 16px;
    color: var(--btn-primary);
    margin-top: 30px;
    margin-bottom: 15px;
}

.insertpic {
    width: 191px;
    height: 120px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
}

.mitrFont {
    font-family: 'Mitr';
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 14px;
}

.primarycolor {
    color: var(--btn-primary);
}

.OVERLINE1 {
    font-family: 'Mitr';
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 12px;
}

.pwd1 {
    position: relative;
}

.pwd2 {
    padding-right: 35px;
}

.pwd3 {
    position: absolute;
    top: 3.5px;
    right: 18px;
    padding: 5px 5px;
}

/* add new */
.Block_Transparent {
    padding: 15px;
}

.color1 {
    color: var(--btn-primary);
}

.Graph_Group {
    display: flex;
    flex-direction: row;
}

.centerDiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

.lineli {
    text-decoration: none;
}

.lineli1 {
    text-decoration: underline;
    color: var(--link-black);
}

.lineli1:hover {
    color: var(--link-black);
}

.lineli2 {
    text-decoration: underline;
}

.fontp {
    text-align: right;
    font-style: italic;
}

.textbold {
    font-weight: 500;
    display: inline;
}

.fontmargin {
    text-indent: 50px;
}

.textptermsofuse {
    text-indent: 80px !important;
}

.textptermsofuse1 {
    text-indent: 85px !important;
    font-size: 1.2rem !important;
}

.texttable {
    font-size: 1.2rem !important;
    text-indent: 10px !important;
}

.texttable1 {
    font-size: 1.2rem !important;
    text-indent: 10px !important;
    margin-bottom: 1em;
}

.fonttersofuse {
    font-size: 22px !important;
    font-weight: 500;
}

.fontdefinition {
    font-size: 22px !important;
    margin-bottom: 16px;
    font-weight: 500;
}

.fonttersofuse1 {
    text-align: right;
    font-style: italic;
}

.fonttersofuse2 {
    font-size: 21px !important;
    font-weight: 500;
}

.fonttersofuse3 {
    font-size: 20px !important;
    font-weight: 500;
}

.texttable2 {
    font-size: 1.2rem !important;
    text-indent: 95px !important;
}