.outline_layout {
    padding-top: 34px;
    padding-bottom: 34px;
    margin: 0px auto;
    flex-grow: 1;
}

    .outline_layout h2 {
        font-family: 'Prompt';
        font-style: normal;
        font-weight: 500;
        line-height: 1.2;
        color: var(--btn-primary-hover);
    }

.imgManaul h3 {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-size: calc(1.375rem + 1.5vw);
    line-height: 1.2;
    color: var(--btn-primary-hover);
}

.Menu a {
    text-decoration: none;
    cursor: pointer;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1;
    color: var(--link-black);
    display: block;
    padding: 9px 0 9px 10px;
}

.imgManaul label {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1;
    color: var(--link-black);
    margin-top: 30px;
    display: block;
}

.MenuManual {
    width: auto;
}

.DetailManual {
    min-width: 300px;
}

.subText label {
    margin-top: 10px;
}

.inline_layout_Menu {
    width: 185px;
}

.inline_layout_Register {
    padding: 0 16px 16px 72px;
    border-left: 1px solid var(--border-color);
}

.subText {
    margin-left: 40px;
}

.imgManaul img {
    display: block;
    margin: auto;
    max-width: 90%;
}

.Menu ul {
    margin: 0;
    padding: 0;
}

.Menu li {
    list-style-type: none;
}

.Menu ul li.Selected {
    background-color: var(--color-purple-02);
}

.roundBtn {
    position: fixed;
    bottom: 50px;
    right: 50px;
    height: 40px;
    width: 40px;
    opacity: 0.5;
    border-radius: 50%;
    border: 1px solid var(--border-color);
    background-color: var(--border-color);
    z-index: 10;
}

.headManual {
    margin-bottom: 34px;
}

@media only screen and (max-width: 991px) {
    .outline_layout {
        margin: 30px 40px 30px 40px;
    }

    .inline_layout_Register {
        padding: 0px 30px 30px 30px;
    }

    .headManual {
        margin-bottom: 1rem;
    }

    .DetailManual {
        min-width: 300px;
    }
}

@media only screen and (max-width: 564px) {
    .inline_layout_Register {
        border: none
    }

    .inline_layout_Register {
        padding: 30px 0px 0px 0px;
    }
}

/***********slide***************/
.slideshow {
    margin: auto;
    overflow: hidden;
    width: 100%;
    text-align: center;
    position: relative;
    height: 500px;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 2000ms;
}

.slide_img {
    width: 100%;
}

.slide {
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 40px;
}

.slideshowDots {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.slideshowDot {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 5px;
    margin-left: 5px;
    background-color: #b2b2b2;
    border: 1px solid var(--border-color);
}

.slideshowDot_active {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 5px;
    margin-left: 5px;
    background-color: #fff;
    border: 2px solid var(--border-color);
}