:root {
    --font-color: #707070;
}

.header4top {
    background-color: #313541;
    height: 30px;
    color: #FFFFFF;
    text-align: center;
}

.header4top span {
    line-height: 250%;
    font-family: 'Mitr';
    font-weight: 100;
    font-size: 12.5px;
}

.Menu {
    background-color: var(--background-primary);
    min-height: 75px;
    padding: 5px 15px 5px 15px;
    font-family: 'Prompt';
    font-size: 1rem;
}

.Menu.Fixed {
    z-index: 5;
    position: fixed;
    top: 0;
    width: 100%;
}

.Menu2 {
    background-color: var(--background-primary);
    width: 100%;
    min-height: 65px;
    padding: 20px 23px;
    font-family: 'Prompt';
    font-size: 1rem;
}

.pad {
    padding: 10px 0px;
}

.bor {
    padding-left: 20px;
    border-left: 4px solid #D9D9D9;
}

.bor a {
    padding: 4px 0px !important;
}

.Menu select {
    border: none;
}

.Menu button {
    margin-left: 0.8em;
}

.Menu ul li.Selected {
    background-color: var(--color-pink-02);
}

.Menu ul li.Selected a {
    color: white !important;
}

.NavTog {
    border: none;
}

.btn_blue {
    background-color: #3A508B;
}

.btn_round {
    border-radius: 100em;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.Footer_Contact {
    padding: 32px;
    border-top: 1px solid var(--font-color);
    background-color: #fff;
    font-family: 'Prompt';
    font-size: 0.9rem;
}

.Footer_Contact .Content {
    margin: 0px auto;
    width: 1000px;
    color: var(--font-color);
}

.Footer_Contact .Content .box {
    padding-left: 60px;
    padding-bottom: 20px;
    margin-left: 5%;
}

@media only screen and (min-width: 993px) {
    .Nav_Mobile {
        display: none !important;
    }

    .Nav_Mobile3 {
        display: none !important;
    }
}

@media only screen and (max-width: 992px) {
    .box {
        width: 100% !important;
    }

    .Footer_Contact1 {
        width: auto !important;
        margin-right: 5em !important;
    }

    .Menu_Mobile button {
        margin: 0px 0 0 10px !important;
    }

    .Nav_Mobile {
        display: -webkit-box !important;
        margin-right: 0px;
        margin-left: auto;
    }

    .Nav_Mobile2 {
        display: none !important;
    }

    .Container_Fluid {
        justify-content: none !important;
    }
}

@media only screen and (max-width: 554px) {
    .btn_nav_mobile {
        font-size: 0.9rem;
    }

    .img_nav_mobile {
        height: 32px;
    }

    .Menu_Mobile button {
        margin: 0px 0 0 5px !important;
    }
}

.Footer_Contact .Content {
    font-size: 0.9rem;
}

.Footer_Contact .Content h5 {
    font-size: 1rem;
}

.Footer_Bottom {
    height: 73px;
    background-color: #3B4154;
    border: 1px solid var(--font-color);
    font-family: 'Prompt';
    font-size: 10px;
    font-weight: 300;
    color: #fff;
    padding-left: var(--menu-width);
}

.Footer_Bottom a,
.Footer_Bottom label {
    color: #fff;
}

.blueline {
    color: var(--link-blue) !important;
    text-decoration: underline;
}

.smt {
    font-size: 1rem;
    color: var(--link-black);
    font-family: Prompt;
}

h5 {
    font-size: 24px;
    font-family: Prompt;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ColorGrey {
    color: #C6C9D2 !important;
}

.ColorWhite {
    color: #fff !important;
}

.noPointer {
    cursor: default;
}

.nolink {
    font-size: 1rem;
    color: #C6C9D2 !important;
    font-family: Prompt;
}

.headBottom {
    border-bottom: 1px solid var(--border-color);
}

.body2 {
    font-size: 1rem;
}

.nocurser {
    cursor: default !important;
}

/********************************************************************/
.Main_Content_Home {
    width: 100%;
    height: auto;
    min-height: calc(100% - (424px));
    padding-top: 75px;
}

.Main_Content_Register {
    width: 100%;
    height: auto;
    min-height: calc(100% - (73px));
    background-color: var(--background-primary);
    padding-top: 75px;
}

.Main_Content_Register.Content_center {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    text-align: center;
    padding-bottom: 25px
}

/************************************************************/
.Main_Content_Customer {
    width: 100%;
    height: auto;
    min-height: calc(100% - (73px));
    background-color: var(--background-primary);
    display: flex;
    flex-direction: row;
    font-family: 'Prompt';
}

.Main_Content_Customer .Menu_Left {
    flex-grow: 1;
    width: var(--menu-width);
    border-right: 1px solid var(--border-color);
    background-color: white;
    z-index: 1;
    position: fixed;
    height: 100%;
    overflow: auto;
    scrollbar-color: transparent;
}

.Main_Content_Customer .Menu_Left:hover {
    overflow: auto;
}

.Main_Content_Customer .Menu_Left .Header {
    height: var(--header-user-info-height);
    /*border-bottom: 1px solid var(--border-color);*/
    text-align: left;
    vertical-align: middle;
    padding-top: 20px;
    padding-left: 25px;
    position: fixed;
    z-index: 1;
    background-color: white;
    width: calc(var(--menu-width) - 1px);
}

.Main_Content_Customer .Menu_Left .Header img {
    height: 25px;
}

.Main_Content_Customer .Menu_Left .Menu {
    margin: 0;
    padding-top: calc(var(--header-user-info-height) + 5px);
}

.Main_Content_Customer .Menu_Left .Menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.Main_Content_Customer .Menu_Left .Menu ul li {
    padding: 0;
    margin: 0;
}

.Main_Content_Customer .Menu_Left .Menu ul li a {
    padding: 10px 0px;
    display: block;
    text-decoration: none;
    color: var(--link-blue-01);
}

.Main {
    padding: 10px 0px;
    display: block;
    text-decoration: none;
    color: var(--link-black);
}

.Main_Content_Customer .Menu_Left .Menu ul li a i {
    margin-right: 5px;
}

.Main_Content_Customer .Content {
    flex-grow: 1;
    /*width: calc(100% - var(--menu-width))*/
    ;
    padding-left: var(--menu-width);
    padding-bottom: 15px;
}

.bg {
    background-color: var(--background-primary);
}

.Main_Content_Customer .Content .Header {
    height: var(--header-user-info-height);
    background-color: white;
    border-bottom: 1px solid var(--border-color);
    padding: var(--padding-default);
    padding-right: 30px;
    position: fixed;
    width: calc(100% - var(--menu-width));
    z-index: 2;
}

.Main_Content_Customer .Content .Content {
    padding-top: var(--header-user-info-height);
    padding-left: 0;
    padding-bottom: 0;
}

/**********************************************/

.menu_item {
    background-color: white;
    /* Add negative margin to counteract the padding */
    margin: -5px -15px !important;
    /* Restore the original padding */
    padding: 0px 15px 0px 15px !important;
}

.menu_item_sub {
    background-color: white;
    margin: -5px -15px !important;
    padding: 0px 15px 0px 15px !important;
    border-left: 4px solid #D9D9D9;
    list-style-type: none;
}

.fontLeft {
    color: #6C757D !important;
}

.menu_item:hover,
.menu_item_sub:hover {
    background-color: var(--color-pink-02);
}

.menu_item:hover a,
.menu_item_sub:hover a.fontLeft {
    color: white !important;
}

.marginSub {
    margin-left: 20px;
}