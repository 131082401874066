.has_date {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    min-width: 120px;
}

    .has_date input {
        position: relative;
        padding-right: 35px;
        z-index: 0;
    }

    .has_date .feedback {
        position: absolute;
        display: block;
        z-index: 3;
        right: 0;
        width: 25px;
        height: 25px;
        top: calc(50% - 12px);
        text-align: center;
        font-size: 1rem;
        pointer-events: none;
        line-height: 1.5;
        color: #949494;
    }

.male {
    color: #8086FF;
}

.female {
    color: #FF80DF;
}

.other {
    color: #6BCA4E;
}

.design_input {
    width: 100%;
}

.UploadFile {
    position: relative;
    overflow: hidden;
}

    .UploadFile button.BtnUploadFile {
        width: 100%;
        min-width: 190px;
        height: 286px;
        min-height: 120px;
    }

        .UploadFile button.BtnUploadFile * {
            pointer-events: none;
        }

    .UploadFile button.BtnClearFile {
        pointer-events: visible;
        position: absolute;
        z-index: 1;
        right: 0.25rem;
        top: 0.25rem;
        padding: 0.15rem 0.5rem;
    }

    .UploadFile input[type=file] {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .UploadFile .FileName {
        display: inline-block;
        margin-right: 30px;
    }

.UploadImage {
    display: flex;
}

    .UploadImage button {
        width: 88px;
        height: 88px;
        border-radius: 100%;
        background-color: var(--border-color);
        color: white;
        text-align: center;
        position: relative;
        overflow: hidden;
    }

        .UploadImage button:hover {
            border-color: var(--border-color);
            color: var(--border-color);
        }

        .UploadImage button * {
            pointer-events: none;
        }

        .UploadImage button i {
            font-size: 26px;
            display: block;
        }

        .UploadImage button img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

    .UploadImage input[type=file] {
        opacity: 0;
        width: 0;
        height: 0;
    }