/***********slide***************/
.AllSlide {
    margin-bottom: 28px;
}

.outline_layout {
    padding-top: 34px;
    padding-bottom: 34px;
    margin: 0px auto;
    flex-grow: 1;
}

.outline_layout h2 {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 34px;
    color: var(--btn-primary-hover);
}

.outline_layout h1 {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    color: var(--btn-primary-hover);
}

.slideshow {
    margin: auto;
    overflow: hidden;
    width: 100%;
    text-align: center;
    position: relative;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
}

.slide_img {
    width: 100%;
}

.slide {
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 40px;
}

.slideshowDots {
    margin-top: 36px;
    text-align: center;
    margin-bottom: 28px;
}

.slideshowDot {
    display: inline-block;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    background-color: var(--primary-gray);
    border: 1px solid var(--primary-gray);
}

.slideshowDot_active {
    display: inline-block;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    background-color: var(--primary-pink);
    border: 2px solid var(--primary-pink);
}

/***********Attribute***************/
.layout_outside_attribute {
    margin-top: 40px;
    display: flex;
    margin-bottom: 28px;
}

.layout_outside_attribute1 {
    display: flex;
}

.layout_attribute {
    width: 300px;
    text-align: center;
    margin-left: 24px;
    padding: 30px 10px;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--color-gray-02);
    background-color: var(--background-primary);
}

.fontConvenience1 {
    color: var(--primary-pink);
    margin: 28px 0;
    text-align: center;
    font-family: Prompt;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.fontConvenience2 {
    color: var(--primary-purple);
    margin: 28px 0;
    text-align: center;
    font-family: Prompt;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.fontConvenience3 {
    color: var(--primary-blue);
    margin: 28px 0;
    text-align: center;
    font-family: Prompt;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.fontConvenience4 {
    color: var(--primary-black);
    margin: 28px 0;
    text-align: center;
    font-family: Prompt;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.fontdesc {
    color: var(--color-gray-03);
}

.imgAttribute {
    text-align: center;
    margin-top: 28px;
}

.AllSlide_Moblie_Attribute {
    display: none;
}

@media only screen and (max-width: 1400px) {
    .Medium_Attribute {
        margin-top: 24px;
    }

    .layout_outside_attribute {
        display: block;
        text-align: center;
    }

    .Medium_Attribute1 {
        margin-left: 0;
    }

    .layout_outside_attribute1 {
        margin-left: auto;
        margin-right: auto;
        display: inline-flex !important;
    }
}

@media only screen and (max-width: 768px) {
    .layout_outside_attribute {
        display: none !important;
    }

    .AllSlide_Moblie_Attribute {
        margin: 28px 0;
        display: contents;
    }

    .layout_attribute {
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
        width: 300px;
    }

    .slideshowDots {
        margin-top: 40px;
        text-align: center;
        margin-bottom: 28px;
    }
}

/**********PackageMockup***********/
.fontHeadPackage {
    font-family: Prompt;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #000;
}

.layout_outside_Package {
    padding: 68px 0px;
}

.AllPackagePeriod {
    margin-top: 34px;
}

.Package_Period {
    background-color: white;
    width: auto;
    display: inline-block;
    border-radius: 10px;
    border: 1px solid var(--primary-black);
    text-align: center;
    flex-grow: 0 !important;
    margin: 22px;
}

.Package_Period .Form_Check {
    display: inline-block;
    padding: 0px;
    margin: 0px;
}

.Package_Period .Form_Check input[type=radio] {
    width: 0px;
    height: 0px;
    opacity: 0;
}

.Package_Period .Form_Check label {
    padding: 3px 15px;
    cursor: pointer;
    font-size: 22px;
    color: var(--primary-black);
    margin: 0px 10px;
}

.Package_Period .Form_Check input[type=radio]:checked+label {
    color: white;
    background-color: var(--primary-black);
    border-radius: 10px;
}

.compareMockup {
    color: var(--primary-black) !important;
    font-family: Prompt;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    padding-right: 36px;
}

.AllPackageBox {
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.Package_Box {
    display: flex;
    width: calc(25% - 1rem);
    min-height: 400px;
    padding: 34px 0px 34px 0px;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    border-radius: 10px;
    border: 1px solid var(--primary-gray);
    background: var(--background-primary);
    position: relative;
}

.Package_Box:hover {
    top: -10px;
    border-color: var(--color-gray-03);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@media only screen and (max-width: 992px) {
    .Package_Box {
        width: calc(50% - 1rem);
    }
}

@media only screen and (max-width: 769px) {
    .Package_Box {
        width: calc(80% - 1rem);
    }
}

.HeadBox {
    display: flex;
    padding: 4px 0px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background: var(--primary-pink);
}

.HeadBox0 {
    display: flex;
    padding: 4px 0px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background: var(--primary-purple);
}

.HeadBox1 {
    display: flex;
    padding: 4px 0px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background: var(--primary-blue);
}

.HeadBox2 {
    display: flex;
    padding: 4px 0px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background: var(--primary-black);
}

.fontHeadBox {
    color: var(--background-primary);
    font-family: Prompt;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.PackagePrice {
    width: 283px;
    height: 88px;
    min-height: 88px;
    padding: 12px 0px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
}

.fontPackagePrice1 {
    color: var(--primary-black);
    font-family: Prompt;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.fontPackagePrice {
    color: red;
    font-family: Prompt;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.fontPackageUnit {
    color: var(--primary-black);
    font-family: Prompt;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Boxline {
    border-bottom: 1px solid var(--primary-gray);
    width: 80%;
    height: 2px;
    background-color: var(--primary-gray);
}

.Feature {
    list-style: none;
    text-align: start;
    margin-top: 25px;
    margin-bottom: 10px;
}

.fontFeature {
    color: var(--primary-black);
    font-family: Prompt;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.btnPackage {
    color: var(--background-primary);
    font-family: Prompt;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    background: var(--primary-pink);
    border-color: var(--primary-pink);
    width: 177px;
    height: 40px;
    flex-shrink: 0;
}

.btnPackage0 {
    color: var(--background-primary);
    font-family: Prompt;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    background: var(--primary-purple);
    border-color: var(--primary-purple);
    width: 177px;
    height: 40px;
    flex-shrink: 0;
}

.btnPackage1 {
    color: var(--background-primary);
    font-family: Prompt;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    background: var(--primary-blue) !important;
    border-color: var(--primary-blue) !important;
    width: 177px;
    height: 40px;
    flex-shrink: 0;
}

.btnPackage2 {
    color: var(--background-primary);
    font-family: Prompt;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    background: var(--primary-black) !important;
    border-color: var(--primary-black) !important;
    width: 177px;
    height: 40px;
    flex-shrink: 0;
}

.btnPackage:hover,
.btnPackage0:hover,
.btnPackage1:hover,
.btnPackage2:hover {
    opacity: 0.8;
}

.Recommand {
    position: absolute;
    background-color: var(--primary-yellow) !important;
    border: 2px solid var(--primary-yellow) !important;
    top: 0;
    color: #fff;
    font-size: 10px;
    padding: 3px 9px;
    border-end-start-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    padding: 5px 33px 5px 34px;
    justify-content: center;
    align-items: center;
}

.Recommand span {
    color: #000;
    font-family: Prompt;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.comparetxt {
    text-align: end;
}

.AllSlide_Moblie_Package {
    display: none;
}

@media only screen and (max-width: 769px) {
    .Package_Period .Form_Check label {
        font-size: 26px;
    }

    .Package_Period {
        margin: 0 0 22px 0;
        width: 100%;
    }

    .Package_Period .Form_Check label {
        padding: 3px 16px;
        margin: 0px;
    }

    .AllPackagePeriod {
        margin: 22px 0 0 0;
    }

    .comparetxt {
        text-align: center;
    }

    .compareMockup {
        padding-right: 0;
    }

    .AllPackageBox {
        display: none;
    }

    .AllSlide_Moblie_Package {
        display: block;
    }

    .Package_Box {
        margin-left: auto;
        margin-right: auto;
    }

    .Recommand {
        top: 0px;
    }
}

/***********Header**********/
.Nav_Medium {
    display: none;
}

.paddingNav1 {
    padding-top: 0px;
}

.Login {
    display: none;
}

.Menu {
    /*background-color: var(--background-primary) !important;*/
    min-height: 90px;
    font-family: 'Prompt';
    font-size: 14px;

}

.MenuContainer {
    padding: 0px 52px;
}

.Menu.Fixed {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

.blueline {
    color: var(--link-blue) !important;
    text-decoration: underline;
}

.smt {

    color: var(--link-black) !important;
    font-family: Prompt;
    text-align: center;
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
    text-decoration: none;
}

.smt1 {

    color: var(--link-black) !important;
    font-family: Prompt;
    text-align: center;
    padding: var(--bs-navbar-nav-link-padding-x);
    text-decoration: none;
}

.btn_blue {
    background-color: var(--primary-pink);
    border-color: var(--primary-pink);
}

.btn_blue:hover {
    background-color: var(--color-pink-02);
    border-color: var(--color-pink-02);
}

.btn_blue:active {
    background-color: var(--color-pink-01) !important;
    border-color: var(--color-pink-01) !important;
}

.btn_round {
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20px;
}

.paddingNav {
    padding-top: 0px;
}

.Main_Content_Home {
    width: 100%;
    padding-top: 94px;
    height: auto;
    /*min-width: 540px;*/
    min-height: calc(100%);
    display: flex;
    flex-direction: column;

}

.Selected {
    color: var(--primary-pink) !important;
}

.MenuContainer {
    display: none;
}

.Nav_Mobile2 {
    display: block;
}

.WidthHeader {
    width: 100%;
}

@media only screen and (max-width: 1198px) {
    .width_mobile_nav {
        max-width: 960px !important;
    }

    .Nav_Mobile2 {
        display: none;
    }

    .Nav_Medium {
        display: block;
    }

    .MenuContainer {
        padding: 0px !important;
    }


    .btn_round {
        font-size: 16px;
    }

    .Main_Content_Home {
        padding-top: 130px;
    }
}

.MenuContainer1 {
    padding-top: 10px;
}

@media only screen and (max-width: 767px) {
    .Main_Content_Home {
        padding-top: 94px;
    }

    .MenuContainer1 {
        display: none;
    }

    .MenuContainer {
        display: block;
    }

    .width_mobile_nav {
        max-width: 540px !important;
        padding: 16px 8px 8px 16px;
    }

    .Login1 {
        display: none;
    }

    .Login {
        display: flex !important;
    }

    .MenuContainer {
        padding: 0px !important;
    }

    .img_nav_mobile_header {
        /*margin-left: 90px;*/
    }

    .paddingNav1 {
        padding-top: 0px !important;
    }

    .Nav_Medium {
        display: none;
    }

    .Nav_Mobile2 {
        display: flex;
    }

    .btn_round {
        font-size: 20px;
    }
}

/**notices**/
.layout_notices {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    align-self: stretch;
}

/**KeyFeatures**/
.keyfeaturesfont {
    /*height: calc(100vh - 94px);*/
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 24px;
    position: relative;
    z-index: 0;
    overflow: hidden;
}

.keyfeaturesfont .layout_keyfeatures {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
}

.keyfont {
    position: relative;
    z-index: 5;
    right: 0;
    color: var(--primary-black);
    font-family: 'Prompt';
    margin: 34px 0px 34px 0px;
    width: 55%;
}

.keyfont1 {
    display: flex;
    padding: 12px 88px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--primary-black);
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 24px;
}

.keyfont .font {
    color: var(--primary-black);
    font-family: Prompt;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
}

.sizeicon {
    width: 32px;
    padding-top: 8px;
    padding-right: 8px;
}

@media only screen and (max-width: 1080px) {
    .keyfeaturesfont .layout_keyfeatures {
        width: auto;
        height: 100%;
        right: 0;
        left: auto;
    }

    .keyfont {
        width: 60%;
    }
}

@media only screen and (max-width: 990px) {
    .keyfont {
        width: 70%;
    }
}

@media only screen and (max-width: 690px) {
    .keyfont {
        width: 100%;
    }
}

/**Security*/
.security {
    display: flex;
    width: 124px;
    height: 124px;
    justify-content: center;
    align-items: center;
}

.fontsecurity {
    color: var(--primary-black);
    font-family: Prompt;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.layout_security {
    display: flex;

}

.layout_security img {
    margin-right: 48px;
    width: 124px;
    height: 124px;
    flex-shrink: 0;
}

.security1 {
    margin: 68px 0px 34px 0px;
    gap: 34px;
    display: flex;
    flex-direction: column;
}

.bordersecurity {
    height: 3px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 52px;
}

.bordersecurity1 {
    width: 798px;
    border-bottom: 3px solid var(--color-gray-02);
}

.AllSlide_Moblie_security {
    display: none;
}

@media only screen and (max-width: 960px) {
    .security1 {
        margin: 48px 38px 48px 38px;
    }

    .fontsecurity {
        padding-bottom: 52px;
    }

    .bordersecurity {
        height: 3px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 0px;
    }

}

@media only screen and (max-width: 769px) {
    .security1 {
        display: none;
    }

    .AllSlide_Moblie_security {
        margin: 48px 42px;
        display: block;
    }

    .slide_security {
        text-align: center;
        min-height: 540px
    }

    .fontsecurity {
        margin-top: 48px;
        text-align: left;
    }

    .bordersecurity {
        height: 3px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 52px;
        border-bottom: 3px solid var(--color-gray-02);
    }
}


/**ContactUs**/
.layout_contactus {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100%);
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.contactus {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
    background: -moz-linear-gradient(86deg, rgba(231, 105, 175, 1) 0%, rgba(99, 179, 230, 1) 100%);
    background: -webkit-linear-gradient(86deg, rgba(231, 105, 175, 1) 0%, rgba(99, 179, 230, 1) 100%);
    background: linear-gradient(86deg, rgba(231, 105, 175, 1) 0%, rgba(99, 179, 230, 1) 100%);
}

.contactusfont {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    margin: 34px 0px 0px 0px;
}

.contactusfont1 {
    display: flex;
    padding: 4px 40px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--background-primary);
    color: var(--background-primary);
    font-family: Prompt;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.fontcontactus {
    display: flex;
    color: var(--background-primary);
    font-family: Prompt;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.fontcontactus label {
    margin-left: 15px;
}

.sizeiconcontactus {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    margin-bottom: 26px;
}

.sizeiconcontactus1 {
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
}

.contactusimg {
    position: relative;
    z-index: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    margin: 0px 42px 32px 0px;
}

.contactusimg a {
    display: inline-block;
    margin-left: 0.5em;
}

@media (max-width: 1400px) {
    .contactus {
        flex-direction: column;
    }
}

@media only screen and (max-width: 960px) {
    .contactus {
        flex-direction: column;
    }

    .layout_contactus {
        height: 100%;
        object-fit: cover;
        object-position: 0%;
    }

    .contactusfont {
        margin: 38px 38px 38px 38px;
        width: auto;
    }

    .contactusimg {
        margin: 0px 22px 38px 28px;
    }

    .fontcontactus {
        font-size: 22px;
    }
}

@media only screen and (max-width: 540px) {
    .layout_contactus {
        height: 100% !important;
        object-fit: cover !important;
        object-position: 0% !important;
    }

    .contactusfont {
        margin: 38px 10px 38px 8px;
        width: auto;
    }

    .contactusimg {
        margin: 0px 38px 38px 28px !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .fontcontactus {
        font-size: 20px;
    }
}


/**footmockup**/
.FooterMock {
    height: 92px auto;
    background: var(--primary-black);
    border: 1px solid var(--font-color);
    font-family: 'Prompt';
    font-size: 10px;
    font-weight: 300;
    color: var(--background-primary);
}

.FooterMock a,
.FooterMock label {
    color: var(--background-primary);
}

.ColorWhite {
    color: var(--primary-gray) !important;
    text-align: center;
    font-family: Prompt;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.PaddingFooter {
    padding: 15px;
}

.maginfooter1 {
    padding-bottom: 7px;
}

/****Cookie*****/

.cookie {
    z-index: 100;
}

/****FeatureMockup****/
.layout_feature {
    margin: 34px 0px 34px 0px;
}

.feature {
    color: var(--primary-black);
    text-align: center;
    font-family: Prompt;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.borderfeature {
    height: 3px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 45px;
}

.borderfeature1 {
    width: 798px;
    border-bottom: 3px solid var(--color-gray-02);
}

@media only screen and (max-width: 960px) {
    .layout_feature {
        margin: 38px 38px 38px 38px;
    }

    .feature {
        font-size: 22px;
    }
}

@media only screen and (max-width: 540px) {
    .layout_feature {
        margin: 38px 42px 38px 42px;
    }

    .feature {
        font-size: 20px;
    }
}

.noticebottom {
    margin-bottom: 28px;
}