.Login_outer_layout {
    font-family: 'Prompt';
    width: 100%;
    height: auto;
    min-height: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 0.9rem;
    flex-grow: 1;
}

.Login_inner_layout {
    margin: 0px auto;
    display: block;
    max-width: 540px;
    width: auto;
    padding: 30px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@media only screen and (max-width: 767px) {
    .Login_inner_layout {
        border: none;
        box-shadow: none;
    }

    .Login_inner_layout img {
        display: none;
    }

    .Login_inner_layout div.col {
        width: 100%;
    }
}

.Login_inner_layout button[type=submit] {
    background-color: var(--btn-primary);
    border-color: var(--btn-primary-border-color);
}

.Login_inner_layout button[type=submit]:hover {
    background-color: var(--btn-primary-hover);
}

.pad {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-right: 32px;
    padding-left: 32px;
}

.line {
    margin-top: 27px;
}

h5 {
    font-size: 2rem;
}

.body2 {
    font-size: 1rem;
}

.body1 {
    font-size: 1rem;
}

.font-color {
    color: var(--link-black);
}

.color {
    color: var(--btn-primary);
    text-align: left;
}

.Login_inner_layout1 {
    margin-right: 1em;
    display: block;
    width: 570px;
    padding: 30px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    text-align: left;
}

.Login_inner_layout2 {
    margin: 0px auto;
    display: block;
    width: 1130px;
    padding: 30px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.Login_inner_layout3 {
    margin: 0px auto;
    display: block;
    width: 480px;
    padding: 30px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.Login_inner_layout4 {
    margin: 0px auto;
    display: block;
    width: 580px;
    padding: 32px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.Login_inner_layout5 {
    margin: 0px auto;
    display: block;
    width: 1120px;
    height: 205px;
    padding: 32px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.Login_inner_layout6 {
    margin: 0px auto;
    display: block;
    width: 339px;
    padding-left: 20px;
    padding-top: 11px;
    padding-bottom: 37px;
    padding-right: 22px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.btn {
    background-color: var(--btn-primary-border-color);
    min-width: 100px;
    border: none;
}

.btn1 {
    width: 147px;
    border: none;
}

.btn2 {
    background-color: var(--btn-primary-border-color);
    width: 190px;
    border: none;
}

.btn3 {
    background-color: var(--btn-primary-border-color);
    width: 108px;
    height: 67px;
    border-top: none;
}

/***/

.Alink_black {
    color: var(--link-black);
    text-decoration: none;
    cursor: pointer;
}

.Alink_black:hover {
    color: var(--link-black);
    text-decoration: underline;
}

.Alink_blue {
    color: var(--link-blue);
    text-decoration: none;
    cursor: pointer;
}

.Alink_blue:hover {
    text-decoration: underline;
}

.tablewidth {
    width: 100%;
    border: 1px solid #C6C9D2;
    border-radius: 5px;
}

.tablecolor {
    background-color: #C6C9D2;
}

.fontcolor {
    color: var(--btn-primary);
}

.fontcolor1 {
    color: var(--btn-primary);
    text-align: center;
}

.pay2 {
    display: inline-flex;
}

.size1 {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: var(--btn-primary);
}

.rt {
    float: right;
}

.fontcolor2 {
    color: #359CE3;
    text-align: center;
}

.thinBorder {
    border: 1px solid;
}

.printBtn {
    box-sizing: border-box;
    border: 1px solid var(--border-color);
    background-color: var(--color-white-01);
    width: 58px;
    height: 36px;
}

.printBtn:hover {
    background-color: var(--color-white-01);
}

.printBtn1 {
    box-sizing: border-box;
    border: 1px solid var(--border-color);
    color: #FFC107;
    background-color: var(--color-white-01);
    width: 58px;
    height: 36px;
}

.brd {
    border-bottom: 1px solid #C6C9D2;
}

.brd1 {
    border-bottom: 1px solid var(--btn-primary);
}

.text1 {
    display: block;
}

.pwd1 {
    position: relative;
}

.pwd2 {
    padding-right: 35px;
}

.pwd3 {
    position: absolute;
    top: 3.5px;
    right: 18px;
    padding: 5px 5px;
}

.fc {
    color: #6C757D;
}

.capchaCenter {
    display: flex;
    justify-content: center;
}